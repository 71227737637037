<template>
    <div class="AddBtnCallC">
        <Svgs @click.native="addCall" :color="color" classes="AddBtnCall" name="icontianjia2"></Svgs>
    </div>
</template>

<script>
    export default {
        props: [
            'color',//图标颜色
        ],
        methods: {
            addCall() {
                this.$emit('add-call');
            }
        }
    }
</script>

<style lang="scss">
    .AddBtnCallC {
        background: #fff;
        width: .4rem !important;
        height: .4rem !important;
        position: fixed;
        right: .35rem;
        bottom: 1.3rem;
        box-shadow: .15rem .15rem .15rem #dedede;
    }

    .AddBtnCall {
        width: .7rem !important;
        height: .7rem !important;
        position: absolute;
        left: -.15rem;
        top: -.15rem;
    }
</style>